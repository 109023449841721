"use strict";

// NOTE TO SELF
// Found better example code to reference for improved a11y on drop menus...
// https://www.w3.org/TR/wai-aria-practices/examples/menubar/menubar-1/menubar-1.html
//
// More at
// https://www.w3.org/TR/wai-aria-practices/
// https://www.w3.org/TR/wai-aria-practices/examples/

function doMobileNavigation() {
	// mobile navigation is one pane at a time, needs to re-arrange some content, and buries layers deeper
	// a11y notes based on: https://www.w3.org/WAI/tutorials/menus/flyout/

	// set the class so the CSS knows what we're doing
		document.querySelector('html').classList.add('js-mobile-nav');

	// get elements we will be referencing later on
		const navMain       = document.querySelector('#navMain');
		const subNavs       = navMain.querySelectorAll('.has-children');
		const special       = document.querySelector('#special');

	

	// add ARIA labels to things that need them
		subNavs.forEach(subNav => {
			subNav.setAttribute('aria-haspopup', true);
			subNav.setAttribute('aria-expanded', false);
		});

	// handle clicks on the subnav toggle
		subNavs.forEach(subNav => {
			let toggle = subNav.querySelector(':scope > a');

			// add in a "back" button for each
				let subList = subNav.querySelector(':scope > .l-2');
				subList.insertAdjacentHTML("afterbegin", `<li class="js-back"><a href="#">BACK</a></li>`);

			// listen for clicks on the open toggle
				toggle.addEventListener("click", function(e) {
					e.preventDefault();

					// whatever, we want to hide all other subNavs
						subNavs.forEach(a => { a.classList.remove('js-nav-active') });

					// now, do we need to do anything with this particular subNav?
						let subNavIsOpen    = subNav.classList.contains("js-nav-active"); // returns true|false
						let newSubNavStatus = !subNavIsOpen; // inverts the value

						subNav.setAttribute("aria-expanded", newSubNavStatus); // applies new value
						subNav.classList.toggle("js-nav-active");
				});

			// keyboard focus
				subNav.addEventListener("focusin", function(e) {
					// whatever, we want to hide all other subNavs
						subNavs.forEach(a => { a.classList.remove('js-nav-active') });

					// now, do we need to do anything with this particular subNav?
						let subNavIsOpen    = subNav.classList.contains("js-nav-active"); // returns true|false
						let newSubNavStatus = !subNavIsOpen; // inverts the value

						subNav.setAttribute("aria-expanded", newSubNavStatus); // applies new value
						subNav.classList.toggle("js-nav-active");
				});

				subNav.addEventListener("focusout", function(e) {
					// whatever, we want to hide all other subNavs
						subNavs.forEach(a => { a.classList.remove('js-nav-active') });

					// now, do we need to do anything with this particular subNav?
						subNav.setAttribute("aria-expanded", false); // applies new value
						subNav.classList.remove("js-nav-active");
				});

			// listen for clicks on the back link
				let backButton = subNav.querySelector(':scope .js-back a');
				backButton.addEventListener('click', function(e){
					e.preventDefault();

					e.currentTarget.closest('.js-nav-active').setAttribute("aria-expanded", false);
					e.currentTarget.closest('.js-nav-active').classList.remove('js-nav-active');
				});
		});
}
function undoMobileNavigation() {
	// unset the css class indicating mobile nav
		document.querySelector('html').classList.remove('js-mobile-nav');

	// get elements we will be referencing later on
		const navMain       = document.querySelector('#navMain');
		const subNavs       = navMain.querySelectorAll(':scope .has-children');
		const special       = document.querySelector('#special');
		const backButtons   = navMain.querySelectorAll(':scope .js-back');

		subNavs.forEach(subNav => {
			// remove all the ARIA stuff to help with disclosure of menu status (they're not hidden at desktop)
			subNav.removeAttribute('aria-haspopup');
			subNav.removeAttribute('aria-expanded');
			subNav.classList.remove("js-nav-active");
		});

		backButtons.forEach(button => {
			// remove them
			button.remove();
		})
}

function doDesktopNavigation() {
	// desktop navigation is a more simple show/hide
		document.querySelector('html').classList.add('js-desktop-nav');

	// get elements we will be referencing later on
		const navMain       = document.querySelector('#navMain');
		const subNavs       = navMain.querySelectorAll('.has-children');

	// add ARIA labels to things that need them
		subNavs.forEach(subNav => {
			subNav.setAttribute('aria-haspopup', true);
			subNav.setAttribute('aria-expanded', false);
		});

	// handle clicks on the subnav toggle
		subNavs.forEach(subNav => {
			let toggle = subNav.querySelector(':scope > a');

			// add in a "back" button for each
				let subList = subNav.querySelector(':scope > .l-2');
				// subList.insertAdjacentHTML("afterbegin", `<li class="js-back"><a href="#">BACK</a></li>`);

			// listen for clicks on the open toggle
				toggle.addEventListener("click", function(e) {
					// e.preventDefault();
				
					// whatever, we want to hide all other subNavs
						subNavs.forEach(a => { a.classList.remove('js-nav-active') });

					// now, do we need to do anything with this particular subNav?
						let subNavIsOpen    = subNav.classList.contains("js-nav-active"); // returns true|false
						let newSubNavStatus = !subNavIsOpen; // inverts the value

						subNav.setAttribute("aria-expanded", newSubNavStatus); // applies new value
						subNav.classList.toggle("js-nav-active");
				});

			// keyboard focus
				subNav.addEventListener("focusin", function(e) {
					// whatever, we want to hide all other subNavs
						subNavs.forEach(a => { a.classList.remove('js-nav-active') });

					// now, do we need to do anything with this particular subNav?
						let subNavIsOpen    = subNav.classList.contains("js-nav-active"); // returns true|false
						let newSubNavStatus = !subNavIsOpen; // inverts the value

						subNav.setAttribute("aria-expanded", newSubNavStatus); // applies new value
						subNav.classList.toggle("js-nav-active");
				});

				subNav.addEventListener("focusout", function(e) {
					// whatever, we want to hide all other subNavs
						subNavs.forEach(a => { a.classList.remove('js-nav-active') });

					// now, do we need to do anything with this particular subNav?
						subNav.setAttribute("aria-expanded", false); // applies new value
						subNav.classList.remove("js-nav-active");
				});

		});
}
function undoDesktopNavigation() {
	// unset the css class indicating mobile nav
		document.querySelector('html').classList.remove('js-desktop-nav');

	// get elements we will be referencing later on
		const navMain       = document.querySelector('#navMain');
		const subNavs       = navMain.querySelectorAll(':scope .has-children');
		const backButtons   = navMain.querySelectorAll(':scope .js-back');

		subNavs.forEach(subNav => {
			// remove all the ARIA stuff to help with disclosure of menu status (they're not hidden at desktop)
			subNav.removeAttribute('aria-haspopup');
			subNav.removeAttribute('aria-expanded');
			subNav.classList.remove("js-nav-active");
		});

		backButtons.forEach(button => {
			// remove them
			button.remove();
		})
}

function doMainNav() {
	// get elements we will be referencing later on
		const html          = document.querySelector('html');
		const navMain       = document.querySelector('#navMain');
		const navMenuToggle = document.querySelector('#menuTrigger');

	// handle clicks on the menu toggle button
		navMenuToggle.addEventListener("click", function(e) {
			e.preventDefault();
			
			let navIsOpen    = navMain.classList.contains("js-nav-active"); // returns true|false
			let newNavStatus = !navIsOpen; // inverts the value

			navMenuToggle.setAttribute("aria-expanded", newNavStatus); // applies new value
			navMain.classList.toggle("js-nav-active");

		});

	// handle keyboard focussing inside the nav
		navMain.addEventListener('focusin', event => {
			navMain.classList.add("js-nav-active");
		});
		navMain.addEventListener('focusout', event => {
			navMain.classList.remove("js-nav-active");
		});
}

// what's the width of the screen?
	let screenWidth = window.matchMedia('(max-width: 1023px)');

// initial load - always run these once on page load
	doMainNav();

	if( screenWidth.matches ) {
		undoDesktopNavigation();
		doMobileNavigation();
	} else {
		undoMobileNavigation();
		doDesktopNavigation();
	}

// watch for changes in window size
	screenWidth.addEventListener("change", (e) => {
		if( e.matches) {
			undoDesktopNavigation();
			doMobileNavigation();
		} else {
			undoMobileNavigation();
			doDesktopNavigation();
		}
	});